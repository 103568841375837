import React from "react";
import "./header.scss";
import {
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import Redes from "../redes/Redes";
import { motion } from "framer-motion";
function Header() {
  return (
    <div className="header-container">
      <div className="header">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          style={{ width: "100%" }}
        >
          <div className="header__text">
            <h1 className="justificado">Hola! Soy Sebas N. Sotelo</h1>
            <p className="justificado">Bienvenido a mi Web!</p>
            <p className="justificado">
              Soy un desarrollador de <span>soluciones</span> digitales
            </p>
            <p className="justificado ciudad">De Corrientes, Argentina.</p>
            <img className="img" src="https://i.imgur.com/jUwWnay.png" alt="" />
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Header;
