import React from "react";
import "./noticiaGrande.css";
import { useEffect, useState, useContext } from "react";
import EditarNoticia from "../editarNoticia/editarNoticia";
import ContextGeneral from "../../servicios/contexto";

function NoticiaGrande({
  id,
  proyecto,
  update,
  desc,
  fecha,
  urlYoutube,
  categorias,
  imagen,
  deploy,
  eliminarNoticia,
}) {
  const context = useContext(ContextGeneral);

  const [categ, setCateg] = useState(null);
  const [showEditar, setShowEditar] = useState(false);

  useEffect(() => {
    setCateg(categorias.split(","));
    console.log(categ);
  }, []);

  return (
    <>
      <div className="tarjetaGrande__container">
        <p className="tarjetaGrande__fecha">{fecha}</p>
        <div className="tarjetaGrande__tarjeta">
          <div className="tarjetaGrande__img">
            <img src={imagen} alt="" />
          </div>
          <div className="tarjetaGrande__texto">
            <a href={deploy}>
              <h3>{proyecto}</h3>
            </a>
            <h4>Update: {update}</h4>
            <p>{desc}</p>
            <a href={deploy} className="item__button" target={"_blank"}>
              <p className="item__button__p">Visitar</p>
            </a>
            <h4>Tecnologias aplicadas:</h4>
            <div className="tarjetaGrande__categorias">
              {categ &&
                categ.map((item, i) => {
                  return <p>{item}</p>;
                })}
            </div>
          </div>
        </div>
      </div>
      {context.estadoUsuario == 1 && (
        <div className="btn__edit__container">
          <p
            className="btn__editar btn__eliminar"
            onClick={() => eliminarNoticia(id)}
          >
            Eliminar
          </p>
          {showEditar ? (
            <>
              <EditarNoticia
                id={id}
                proyecto2={proyecto}
                update2={update}
                descripcion2={desc}
                fecha2={fecha}
                urlYoutube2={urlYoutube}
                categorias2={categorias}
                deploy2={deploy}
                imagen2={imagen}
                setShow={setShowEditar}
              />
              <p
                className="btn__editar"
                onClick={() => setShowEditar(!showEditar)}
              >
                Cerrar
              </p>
            </>
          ) : (
            <p
              className="btn__editar"
              onClick={() => setShowEditar(!showEditar)}
            >
              Editar Noticia
            </p>
          )}
        </div>
      )}
    </>
  );
}

export default NoticiaGrande;
