import React, { useContext, useState } from "react";
import "./nuevaNoticia.css";
import firebaseApp from "../../servicios/firebase";
import ContextGeneral from "../../servicios/contexto";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import imageCompression from "browser-image-compression";
import { useEffect } from "react";

function NuevaNoticia({ setShow }) {
  const context = useContext(ContextGeneral);
  const { setNoticias } = useContext(ContextGeneral);

  const firestore = getFirestore(firebaseApp);
  const storage = getStorage(firebaseApp);

  const [carga, setCarga] = useState(true);
  const [url, setUrl] = useState("");

  const agregarNoticia = async (e) => {
    e.preventDefault(e);

    const proyecto = e.target.inputProyecto.value;
    const update = e.target.inputUpdate.value;
    const descripcion = e.target.inputDescripcion.value;
    const fecha = e.target.inputFecha.value;
    const urlYoutube = e.target.inputUrlYoutube.value;
    const categorias = e.target.inputCategorias.value;
    const deploy = e.target.inputDeploy.value;

    //traemos los datos de base de datos
    const docRef = doc(firestore, `users/sebassotelo97@gmail.com`);
    const consulta = await getDoc(docRef);
    const infoDocu = consulta.data();

    //filtramos la propiedad .items y creamos un array nuevo

    const newArray = [];

    newArray.push(
      {
        id: new Date().getTime(),
        proyecto: proyecto,
        update: update,
        descripcion: descripcion,
        fecha: fecha,
        urlYoutube: urlYoutube,
        categorias: categorias,
        imagen: url,
        deploy: deploy,
      },
      ...infoDocu.noticias
    );

    setNoticias(newArray);
    console.log("array", newArray);

    //seteamos el estado y updateamos la base de datos
    //   setArray(newArray);
    updateDoc(docRef, { noticias: [...newArray] });

    //   //limpiar Form
    setUrl(null);
    e.target.inputProyecto.value = "";
    e.target.inputUpdate.value = "";
    e.target.inputDescripcion.value = "";
    e.target.inputFecha.value = "";
    e.target.inputUrlYoutube.value = "";
    e.target.inputCategorias.value = "";
    e.target.inputDeploy.value = "";

    setShow(false);
  };

  const fileHandler = async (e) => {
    setCarga(null);
    //detectar el archivo
    const archivoLocal = e.target.files[0];

    const options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(archivoLocal, options);
    //cargarlo a firebase storage
    const archivoRef = ref(storage, `${archivoLocal.name}`);
    await uploadBytes(archivoRef, compressedFile);
    //obtener URL
    const urlImg = await getDownloadURL(archivoRef);
    setUrl(urlImg);
    setCarga(true);
  };

  useEffect(() => {}, []);

  return (
    <div className="nuevaNoticia__container">
      <form action="" className="nuevaNoticia__form" onSubmit={agregarNoticia}>
        <p>Proyecto:</p>
        <input id="inputProyecto" type="text" />
        <p>Update:</p>
        <input id="inputUpdate" type="text" />
        <p>Descripcion</p>
        <textarea
          id="inputDescripcion"
          className="nuevaNoticia__textArea"
          type="text"
        />
        <p>Fecha:</p>
        <input id="inputFecha" type="text" />
        <p>Url de YouTube:</p>
        <input id="inputUrlYoutube" type="text" />
        <p>Categorias:</p>
        <input id="inputCategorias" type="text" />
        <p>Deploy:</p>
        <input id="inputDeploy" type="text" />
        <p>Imagenes:</p>
        <input type="file" name="" id="" onChange={fileHandler} />
        {carga && <button type="submit">Agregar Noticia</button>}
      </form>
    </div>
  );
}

export default NuevaNoticia;
