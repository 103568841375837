import React from "react";
import "./redes.css";
import {
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineYoutube,
  AiFillGithub,
} from "react-icons/ai";
import { FaTwitch } from "react-icons/fa";
import { SiTiktok } from "react-icons/si";

import { motion } from "framer-motion";

import { useNavigate, useLocation } from "react-router-dom";

function Redes() {
  const location = useLocation();
  return (
    <>
      {location.pathname != "/links" && (
        <div className="redes__container">
          <a
            href="https://www.linkedin.com/in/sebassotelo/"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillLinkedin className="redes__icon linkedin" />
          </a>
          <a
            href="https://github.com/Sebassotelo"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillGithub className="redes__icon" />
          </a>
          <a
            href="https://www.instagram.com/_sebasdev/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <AiOutlineInstagram className="redes__icon" />
          </a>

          <a
            href="https://twitter.com/_sebassotelo"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <AiOutlineTwitter className="redes__icon" />
          </a>

          <a
            href="https://www.youtube.com/channel/UCjnn1S2FKvvkicwEuoBlmAQ"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <AiOutlineYoutube className="redes__icon" />
          </a>
          <a
            href="https://www.twitch.tv/sebassdev"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <FaTwitch className="redes__icon" style={{ fontSize: "26px" }} />
          </a>
        </div>
      )}
    </>
  );
}

export default Redes;
