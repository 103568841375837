import React from "react";
import "./noticiaChica.css";
import { useEffect, useState, useContext } from "react";
import ContextGeneral from "../../servicios/contexto";

function NoticiaChica({
  id,
  proyecto,
  update,
  desc,
  fecha,
  urlYoutube,
  categorias,
  imagen,
  deploy,
}) {
  const context = useContext(ContextGeneral);

  const [categ, setCateg] = useState(null);

  useEffect(() => {
    setCateg(categorias.split(" "));
    console.log(categ);
  }, []);

  return (
    <div className="tarjetaChica__container">
      <div className="tarjetaChica__img">
        <img src={imagen} alt="" />
      </div>
      <div className="tarjetaChica__text">
        <h3 className="tarjetaChica__titulo">{proyecto}</h3>
        <p className="tarjetaChica__fecha">{fecha}</p>
        <p>Update: {update}</p>
      </div>
    </div>
  );
}

export default NoticiaChica;
