// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCLZrhNZ-bSFcplny7_y7hV_iqaEtFYCww",
  authDomain: "portfolio-9871a.firebaseapp.com",
  projectId: "portfolio-9871a",
  storageBucket: "portfolio-9871a.appspot.com",
  messagingSenderId: "401726536933",
  appId: "1:401726536933:web:a91a44280ca28d80a1e80c",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
