import React from "react";
import "./about-me.css";
import Foto from "../../media/foto.png";
import { useNavigate, useLocation } from "react-router-dom";
function About() {
  const navigate = useNavigate();

  return (
    <div className="about">
      <div className="about-container">
        <div className="foto">
          <img src={Foto} alt="" />
        </div>

        <div className="info">
          <h3 className="title">Sobre Mi</h3>
          <div className="separador"></div>
          <p>
            Soy un <span>Desarrollador Full Stack</span> especializado en{" "}
            <span>crear soluciones digitales personalizadas</span> para
            potenciar negocios y emprendedores. Domino herramientas como
            React.js, Next.js, Node.js, Firebase, y más, con un enfoque en
            diseño moderno y funcionalidad escalable.
          </p>
          <p>
            Soy proactivo, detallista, y apasionado por resolver problemas
            tecnológicos. Siempre estoy aprendiendo nuevas tecnologías para
            ofrecer soluciones innovadoras. Mi objetivo es transformar ideas en
            productos digitales que generen impacto real.
          </p>

          <div className="btnes__about">
            <div className="cv scale-up-center">
              <a
                className="aCv"
                href="https://drive.google.com/file/d/1jgHbQDQlJLToev5Yn06qrox_4_TeR6n2/view?usp=sharing"
                target={"_blank"}
                rel="noreferrer"
              >
                <p className="cv__p">Ver CV</p>
              </a>
            </div>
            <div className="cv">
              <a
                className="aCv"
                href="https://www.linkedin.com/in/sebassotelo/"
                target={"_blank"}
                rel="noreferrer"
              >
                <p className="cv__p">Ir a Linkedin</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
