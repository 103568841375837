import React, { useEffect } from "react";
import "./video.css";

import { motion } from "framer-motion";

function Video({ title, fecha, desc, url, img }) {
  const styleImg = {
    backgroundImage: "url(https://i.imgur.com/tmSOTL0.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const fechaOrdenada = fecha.split("T");
  const fechaNueva = fechaOrdenada[0];
  const horaNueva = fechaOrdenada[1].substr(0, fechaOrdenada[1].length - 1);

  useEffect(() => {}, []);
  return (
    <div className="video__container">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{ width: "100%", display: "grid", justifyItems: "center" }}
      >
        <div className="video__title">
          <a
            href="https://www.youtube.com/channel/UCjnn1S2FKvvkicwEuoBlmAQ"
            className="video__title__img"
            style={styleImg}
            target={"_blank"}
          ></a>

          <div className="video__title__text">
            <h3 className="title__text__h3">{title}</h3>
            <p className="title__text__p">
              {horaNueva} {fechaNueva}
            </p>
          </div>
        </div>
        <div className="video__desc">
          <p className="video__desc__p">{desc}</p>
        </div>
        <div className="video_iframe">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${url}?rel=0`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </motion.div>
    </div>
  );
}

export default Video;
