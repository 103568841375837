import React from "react";
import "./skills.css";
import { AiFillHtml5 } from "react-icons/ai";
import { DiCss3, DiJavascript1, DiReact, DiSass } from "react-icons/di";
import { FaGit, FaBootstrap, FaNodeJs } from "react-icons/fa";

import {
  SiFirebase,
  SiNextdotjs,
  SiTailwindcss,
  SiExpress,
  SiPostman,
} from "react-icons/si";

function Skills() {
  return (
    <div className="container-skills">
      <div className="skills">
        <div className="skills-title">
          <h3>Tecnologias</h3>
          <div className="separador-skills"></div>
        </div>

        <div className="skills-desc">
          <div className="skills-item">
            <DiReact className="skills-icon" />
            <p>React Js</p>
          </div>
          <div className="skills-item">
            <SiNextdotjs className="skills-icon" />
            <p>Next Js</p>
          </div>
          <div className="skills-item">
            <FaNodeJs className="skills-icon" />
            <p>Node Js</p>
          </div>
          <div className="skills-item">
            <SiExpress className="skills-icon" />
            <p>Express Js</p>
          </div>
          <div className="skills-item">
            <SiPostman className="skills-icon" />
            <p>Postman</p>
          </div>
          <div className="skills-item">
            <SiFirebase className="skills-icon" />
            <p>Firebase</p>
          </div>

          <div className="skills-item">
            <DiSass className="skills-icon" />
            <p>Sass</p>
          </div>
          <div className="skills-item">
            <FaBootstrap className="skills-icon" />
            <p>Bootstrap</p>
          </div>
          <div className="skills-item">
            <SiTailwindcss className="skills-icon" />
            <p>Tailwind</p>
          </div>
          <div className="skills-item">
            <FaGit className="skills-icon" />
            <p>GIT</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
