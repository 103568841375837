import React from "react";
import "./beneficios.css";

import { AiOutlineMobile } from "react-icons/ai";
import { MdOutlineDesignServices } from "react-icons/md";
import { GoDashboard } from "react-icons/go";
import { motion } from "framer-motion";

function Beneficios() {
  return (
    <div className="beneficios__container">
      <div className="beneficios">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { duration: 0.5 },
          }}
          style={{ width: "100%", display: "grid", justifyItems: "center" }}
        >
          <div className="beneficios__item">
            <MdOutlineDesignServices className="beneficios__icon" />
            <h4 className="beneficios__h4">Diseño y desarrollo</h4>
            <p className="beneficios__p">
              Diseño y desarrollo segun los requisitos del proyecto
            </p>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { duration: 1 },
          }}
          style={{ width: "100%", display: "grid", justifyItems: "center" }}
        >
          <div className="beneficios__item">
            <AiOutlineMobile className="beneficios__icon" />
            <h4 className="beneficios__h4">Responsive</h4>
            <p className="beneficios__p">
              Diseño adaptado a todos los dispositivos.
            </p>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { duration: 1.5 },
          }}
          style={{ width: "100%", display: "grid", justifyItems: "center" }}
        >
          <div className="beneficios__item">
            <GoDashboard className="beneficios__icon" />
            <h4 className="beneficios__h4">Dashboard</h4>
            <p className="beneficios__p">Posibilidad de tener autogestion.</p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Beneficios;
