import React, { useContext, useEffect } from "react";
import ContextGeneral from "../../servicios/contexto";
import "./loader.css";

function Loader({ setLoader }) {
  const context = useContext(ContextGeneral);
  const { llamadaDB } = useContext(ContextGeneral);

  const cargando = () => {
    llamadaDB();
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  useEffect(() => {
    cargando();
  }, []);

  return (
    <div className="container-loader">
      <img
        src="https://i.imgur.com/r3XyYLS.jpg"
        className="img__loader"
        alt=""
      />
    </div>
  );
}

export default Loader;
