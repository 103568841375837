import React from "react";
import style from "./Youtube.module.scss";

function Youtube() {
  const arrayVideos = [
    {
      url: "https://www.youtube.com/embed/cuxXi3QJ0ZE?si=H4_M5_MSskpI8oFl",
      titulo:
        "Como SUBIR IMAGENES mediante API | IMGBB | Te lo explico SENCILLO",
    },
    {
      url: "https://www.youtube.com/embed/XvV2jO_uI6U?si=pKbaFkRvb_yTZxcX",
      titulo:
        "Como IMPORTAR / EXPORTAR / CLONAR una base de datos de FIREBASE | Te lo explico SENCILLO",
    },
    {
      url: "https://www.youtube.com/embed/OupP9MgZwL0?si=on_i6bcPPjobwEXX",
      titulo:
        "Uso de useState ¿Como Funciona? | REACT ⚛️ | Te lo explico SENCILLO",
    },
  ];

  return (
    <div className={style.main}>
      <div className={style.container}>
        <img
          src="https://cdn.prod.website-files.com/6060e6c66acd5a7636de522c/64d68edb50a70ee26eebc10e_yt_logo_mono_light.png"
          alt=""
        />

        <h3>¡Aprende y crece conmigo en mis proyectos!</h3>

        <div className={style.videos}>
          {arrayVideos.map((item) => (
            <div className={style.video__item}>
              <iframe
                src={item.url}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p>{item.titulo}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Youtube;
