import React, { useEffect, useState } from "react";
import "./portfolio.css";
import Item from "./portfolio-item/portfolio-item";
import { AiOutlineHtml5 } from "react-icons/ai";

import { SiFirebase, SiNextdotjs } from "react-icons/si";
import { DiReact, DiCss3 } from "react-icons/di";
import NuevoItem from "./nuevoItem/NuevoItem";

function Portfolio() {
  const [items, setItems] = useState([]);

  const proyectos = [
    {
      titulo: "App de Restaurante",
      desc: "Diseñada para tomar pedidos en tiempo real, especialmente para optimizar la gestión de negocios. Permite administrar clientes de manera eficiente, crear promociones personalizadas, y recibir notificaciones instantáneas de nuevos pedidos. Además, cuenta con herramientas para organizar productos, monitorear el estado de los pedidos, y analizar el rendimiento del negocio.",
      img: "https://i.imgur.com/3G4OYQP.png",
      url: "https://www.gogo.com.ar/",
      tecnologias: ["Next.js", "Firebase", "Sass", "Git", "Github", "Vercel"],
    },
    {
      titulo: "Catalogos Online",
      desc: "Diseñada para crear y personalizar tu catálogo digital de forma sencilla, destacando tus productos con imágenes y descripciones atractivas. Además, puedes crear promociones especiales para impulsar tus ventas y recibir pedidos directamente en tu WhatsApp, ofreciendo una experiencia rápida y cómoda para ti y tus clientes.",
      img: "https://i.imgur.com/yo6JNyT.png",
      url: "https://www.myshop.com.ar/",
      tecnologias: ["Next.js", "Firebase", "Sass", "Git", "Github", "Vercel"],
    },
    {
      titulo: "GymApp",
      desc: "App para gimnasios/entrenadores que necesiten llevar un control de la mensualidad de sus alumnos.",
      img: "https://i.imgur.com/yRZCSqa.png",
      url: "https://gymapp-psi.vercel.app/",
      tecnologias: ["Next.js", "Firebase", "Sass", "Git", "Github", "Vercel"],
    },
    {
      titulo: "JStore",
      desc: "Tienda Virtual De SrasMedias, emprendimiento de venta de medias.",
      img: "https://i.imgur.com/lbuoZOm.png",
      url: "https://www.srasmedias.com.ar/",
      tecnologias: [
        "HTML",
        "CSS",
        "JavaScript",
        "Next.js",
        "Firebase",
        "Sass",
        "Git",
        "Github",
      ],
    },
    {
      titulo: "JaimePrint",
      desc: "Tienda Virtual De JaimePrint.",
      img: "https://i.imgur.com/cHjdEJm.png",
      url: "https://www.jaimeprint.com.ar/",
      tecnologias: [
        "HTML",
        "CSS",
        "JavaScript",
        "Next.js",
        "Firebase",
        "Sass",
        "Git",
        "Github",
      ],
    },
    {
      titulo: "MessageMarker",
      desc: "Marca los mensajes del chat de Twitch como favoritos, y muestralos en tu stream mediante un enlace.",
      img: "https://i.imgur.com/lKWVxFJ.png",
      url: "https://message-marker.vercel.app/",
      tecnologias: [
        "Next.js",
        "Sass",
        "Firebase",
        "Git",
        "Frammer Motion",
        "Js",
      ],
    },
    {
      titulo: "Links App",
      desc: "App para tener todos tus Links en un solo link, tenes que crear la cuenta, configuras el perfil, agregas los links y listo!",
      img: "https://i.imgur.com/w5rNNyn.png",
      url: "https://links-tau.vercel.app/",
      tecnologias: [
        "HTML",
        "CSS",
        "JavaScript",
        "Next.js",
        "Firebase",
        "Git",
        "Github",
      ],
    },
    {
      titulo: "Pagina Personal",
      desc: "Pagina Personal de Sebas Sotelo.",
      img: "https://i.imgur.com/6fiJRU4.png",
      url: "https://www.sebassotelo.com.ar/",
      tecnologias: ["HTML", "CSS", "JavaScript", "React.js", "Git", "Github"],
    },
  ];

  useEffect(() => {
    setItems(proyectos.slice(0, 3));
  }, []);

  const mostrarProyectos = () => {
    setItems(proyectos);
  };

  return (
    <div className="container-port">
      <div className="container-portfolio">
        <div className="port-title">
          <h3>Proyectos</h3>
          <div className="separador-portafolio"></div>
        </div>

        <div className="port-proyectos">
          {items.map((item) => {
            return (
              <NuevoItem
                titulo={item.titulo}
                desc={item.desc}
                img={item.img}
                url={item.url}
                tecnologias={item.tecnologias}
              />
            );
          })}

          {items.length === proyectos.length ? (
            <button
              className="btn__ver"
              onClick={() => setItems(proyectos.slice(0, 3))}
            >
              Ver Menos
            </button>
          ) : (
            <button className="btn__ver" onClick={mostrarProyectos}>
              Ver mas Proyectos
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
