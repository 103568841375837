import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NoRouteView() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);

  return <div></div>;
}

export default NoRouteView;
