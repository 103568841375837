import { useEffect, useContext } from "react";
import "./noticiasView.css";
import { useNavigate } from "react-router-dom";
import ContextGeneral from "../../servicios/contexto";
import { onAuthStateChanged } from "firebase/auth";

import NoticiaGrande from "../../componentes/noticiaGrande/noticiaGrande";
import NuevaNoticia from "../../componentes/nuevaNoticia/nuevaNoticia";

import { motion } from "framer-motion";
import { useState } from "react";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import firebaseApp from "../../servicios/firebase";

function Noticiasview() {
  const context = useContext(ContextGeneral);
  const { inspectorSesion, setNoticias, llamadaDB } =
    useContext(ContextGeneral);

  const [search, setSearch] = useState("");
  const [noticiasItem, setNoticiasItem] = useState([]);
  const [showNoticiaNueva, setShowNoticiaNueva] = useState(false);

  const firestore = getFirestore(firebaseApp);

  useEffect(() => {
    llamadaDB();

    onAuthStateChanged(context.auth, inspectorSesion);
    window.scroll(0, 0);
    document.title = "Noticias | Sebas Sotelo";
    setNoticiasItem(context.noticias);
  }, [context.loader]);

  const buscador = (e) => {
    e.preventDefault(e);
    setSearch(e.target.value);

    const objetosFiltrados = context.noticias.filter(
      (objeto) =>
        objeto.proyecto.toLowerCase().includes(search.toLowerCase()) ||
        objeto.categorias.toLowerCase().includes(search.toLowerCase()) ||
        objeto.update.toLowerCase().includes(search.toLowerCase())
    );

    setNoticiasItem(search ? objetosFiltrados : context.noticias);
  };

  const eliminarNoticia = async (id) => {
    const nuevoArray = context.noticias.filter((item) => item.id != id);

    const docRef = doc(firestore, `users/sebassotelo97@gmail.com`);
    await updateDoc(docRef, { noticias: [...nuevoArray] });

    setNoticias(nuevoArray);
    setNoticiasItem(nuevoArray);
  };

  return (
    <div className="noticiasView__container">
      <div className="noticiasView__container__header">
        {context.estadoUsuario == 1 && (
          <div>
            {showNoticiaNueva ? (
              <>
                <div className="noticiasView__nuevaNoticia">
                  <NuevaNoticia setShow={setShowNoticiaNueva} />
                  <p
                    className="noticiasView__nuevaNoticia__btn"
                    onClick={() => setShowNoticiaNueva(false)}
                  >
                    Cerrar
                  </p>
                </div>
              </>
            ) : (
              <div className="noticiasView__nuevaNoticia">
                <p
                  className="noticiasView__nuevaNoticia__btn"
                  onClick={() => setShowNoticiaNueva(true)}
                >
                  Noticia Nueva
                </p>
              </div>
            )}
          </div>
        )}

        <h2 className="noticiasView__h2">Noticias</h2>

        <form className="noticiasView__buscador">
          <input
            type="text"
            placeholder="Ingrese un Proyecto / Tecnologia / Update"
            id="noticiasView__buscador"
            onChange={buscador}
          />
        </form>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{ width: "100%" }}
      >
        <div className="noticiasView__noticias">
          {noticiasItem.map((item, i) => {
            return (
              <>
                <NoticiaGrande
                  id={item.id}
                  proyecto={item.proyecto}
                  update={item.update}
                  desc={item.descripcion}
                  imagen={item.imagen}
                  deploy={item.deploy}
                  fecha={item.fecha}
                  categorias={item.categorias}
                  eliminarNoticia={eliminarNoticia}
                />
              </>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
}

export default Noticiasview;
