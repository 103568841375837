import React, { createContext, useEffect, useState } from "react";
import ContextGeneral from "./contexto";
import firebaseApp from "./firebase";

import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

function ContextNoticias(props) {
  const [noticias, setNoticias] = useState([]);
  const [noticiasOriginal, setNoticiasOriginal] = useState([]);
  const [estadoUsuario, setEstadoUsuario] = useState(0);
  const [user, setUser] = useState(null);
  // 0 = No logueado
  // 1 = Logueado
  const [loader, setLoader] = useState(false);

  const auth = getAuth(firebaseApp);
  const firestore = getFirestore(firebaseApp);

  const inspectorSesion = (usuarioFirebase) => {
    //en caso de que haya seison iniciada
    if (usuarioFirebase) {
      setUser(usuarioFirebase);
      if (usuarioFirebase.email == "sebassotelo97@gmail.com") {
        setEstadoUsuario(1);
      }
    } else {
      //en caso de que haya seison iniciada
      setUser(null);
      setEstadoUsuario(0);
    }
  };

  const llamadaDB = async () => {
    const docRef = doc(firestore, `users/sebassotelo97@gmail.com`);
    const consulta = await getDoc(docRef);
    const infoDocu = consulta.data();
    setNoticias(infoDocu.noticias);
    setLoader(true);
  };

  return (
    <ContextGeneral.Provider
      value={{
        loader: loader,
        auth: auth,
        user: user,
        noticias: noticias,
        estadoUsuario: estadoUsuario,
        setUser,
        setEstadoUsuario,
        setNoticias,
        inspectorSesion,
        llamadaDB,
      }}
    >
      {props.children}
    </ContextGeneral.Provider>
  );
}

export default ContextNoticias;
