import React, { useContext, useState } from "react";
import firebaseApp from "../../servicios/firebase";
import ContextGeneral from "../../servicios/contexto";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useEffect } from "react";
import "./editarNoticia.css";

function EditarNoticia({
  id,
  proyecto2,
  update2,
  descripcion2,
  fecha2,
  urlYoutube2,
  categorias2,
  deploy2,
  imagen2,
  setShow,
}) {
  const context = useContext(ContextGeneral);
  const { setNoticias } = useContext(ContextGeneral);

  const firestore = getFirestore(firebaseApp);
  const storage = getStorage(firebaseApp);

  const [carga, setCarga] = useState(true);
  const [url, setUrl] = useState(imagen2);

  const editarNoticia = async (e) => {
    e.preventDefault();
    const proyecto = e.target.inputProyecto.value;
    const update = e.target.inputUpdate.value;
    const descripcion = e.target.inputDescripcion.value;
    const fecha = e.target.inputFecha.value;
    const urlYoutube = e.target.inputUrlYoutube.value;
    const categorias = e.target.inputCategorias.value;
    const deploy = e.target.inputDeploy.value;

    const nuevaNoticia = {
      id: id,
      proyecto: proyecto,
      update: update,
      descripcion: descripcion,
      fecha: fecha,
      urlYoutube: urlYoutube,
      categorias: categorias,
      imagen: url,
      deploy: deploy,
    };

    console.log("noticia nueva", nuevaNoticia);

    //traemos los datos de base de datos

    //filtramos la propiedad .items y creamos un array nuevo

    const noticiasCopia = [...context.noticias];
    const index = noticiasCopia.findIndex((item) => item.id === id);

    // Actualizar la noticia en el array copiado
    noticiasCopia[index] = nuevaNoticia;

    //seteamos el estado y updateamos la base de datos

    const docRef = doc(firestore, `users/sebassotelo97@gmail.com`);
    await updateDoc(docRef, { noticias: [...noticiasCopia] });

    setNoticias(noticiasCopia);

    //   //limpiar Form
    setUrl(null);
    e.target.inputProyecto.value = "";
    e.target.inputUpdate.value = "";
    e.target.inputDescripcion.value = "";
    e.target.inputFecha.value = "";
    e.target.inputUrlYoutube.value = "";
    e.target.inputCategorias.value = "";
    e.target.inputDeploy.value = "";

    setShow(false);
  };

  const fileHandler = async (e) => {
    setCarga(null);
    //detectar el archivo
    const archivoLocal = e.target.files[0];
    //cargarlo a firebase storage
    const archivoRef = ref(storage, `${archivoLocal.name}`);
    await uploadBytes(archivoRef, archivoLocal);
    //obtener URL
    const urlImg = await getDownloadURL(archivoRef);
    setUrl(urlImg);
    setCarga(true);
  };

  return (
    <div className="nuevaNoticia__container">
      <form action="" className="nuevaNoticia__form" onSubmit={editarNoticia}>
        <p>Proyecto:</p>
        <input
          id="inputProyecto"
          defaultValue={proyecto2 ? proyecto2 : ""}
          type="text"
        />
        <p>Update:</p>
        <input
          id="inputUpdate"
          defaultValue={update2 ? update2 : ""}
          type="text"
        />
        <p>Descripcion</p>
        <textarea
          id="inputDescripcion"
          defaultValue={descripcion2 ? descripcion2 : ""}
          className="nuevaNoticia__textArea"
          type="text"
        />
        <p>Fecha:</p>
        <input
          id="inputFecha"
          defaultValue={fecha2 ? fecha2 : ""}
          type="text"
        />
        <p>Url de YouTube:</p>
        <input
          id="inputUrlYoutube"
          defaultValue={urlYoutube2 ? urlYoutube2 : ""}
          type="text"
        />
        <p>Categorias:</p>
        <input
          id="inputCategorias"
          defaultValue={categorias2 ? categorias2 : ""}
          type="text"
        />
        <p>Deploy:</p>
        <input
          id="inputDeploy"
          defaultValue={deploy2 ? deploy2 : ""}
          type="text"
        />
        <p>Imagenes:</p>
        <input type="file" name="" id="" onChange={fileHandler} />

        {carga && <button type="submit">Agregar Noticia</button>}
      </form>
    </div>
  );
}

export default EditarNoticia;
