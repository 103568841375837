import React from "react";
import style from "./Info.module.scss";
import { useNavigate, useLocation } from "react-router-dom";

function Info() {
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <img src="https://i.imgur.com/SyPyUhQ.jpeg" alt="" />

      <div className={style.text}>
        <h3>Hola! Soy</h3>
        <h3>Sebastian Sotelo</h3>
        <p>
          Ayudo a emprendedores, negocios y profesionales a resolver problemas
          reales con aplicaciones digitales que generan valor tangible. Creo
          herramientas innovadoras que impulsan su crecimiento y convierten
          objetivos en resultados concretos. Además creo contenido para
          desarrolladores, compartiendo conocimientos y experiencias para
          aprender y crecer juntos en el mundo digital.
        </p>

        <button
          className={style.btn}
          onClick={() => navigate("/sobre-mi#about-me")}
        >
          Seguir leyendo{" "}
          <img
            src="https://cdn.prod.website-files.com/6060e6c66acd5a7636de522c/6060e6c66acd5a4e7bde526a_arrow-cta.svg"
            alt=""
          />{" "}
        </button>
      </div>
    </div>
  );
}

export default Info;
